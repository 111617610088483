<template>
	<div class="main1">
		<div class="icon">
			<img src="../../../assets/images/personalCenter/realname-authentication.png">
		</div>
		<div class="">
			{{$t("certifiedauthsucceeded")}}
		</div>
	</div>
</template>

<script>
	import {
		myInfo
	} from "@/api/Mine";
	export default {
		props: {},
		data(){
			return {
				
			}
		},
		created() {
			this.MyInfo()
		},
		methods: {
			MyInfo(){
				myInfo({}).then((res) => {
				      console.log(res,'我的信息成功');
					  // this.infoObj = res.data
					  this.$store.state.identity = res.data.identity
					  this.$store.state.bankList = res.data.bankcard
					  this.$store.state.groupType = res.data.group_type
					  this.$store.state.isBoss = res.data.is_boss
					  this.$store.state.groupId = res.data.group_id
					  localStorage.setItem('myInfo', JSON.stringify(this.infoObj))
					  // console.log(this.$store.state.groupType,'userInfouserInfouserInfo222222222222')
					  console.log(this.infoObj,'JSON.stringify(this.infoObj)');
					  
					  // if(this.$store.state.groupType == 0){
					  // 	this.tabsList = this.tabsList1
					  // }else{
					  // 	this.tabsList = this.tabsList2
					  // }
				    })
				    .catch((err) => {
				      console.log(err,'我的信息失败');
				    });
			}
		}
	}
</script>

<style lang="less" scoped>
	.main1 {
		width: 100%;
		height: 650px;
		background: @recommendMusicianListBGColor;
		border-radius: 6px;
		backdrop-filter: blur(50px);
		margin-top: 16px;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		color: @musicLibraryFilterMenuNavTextColor;
		.icon{
			margin-bottom: 24px;
			img {
				width: 183px;
				height: 183px;
			}
		}
		.button{
			width: 172px;
			height: 38px;
			background: #3370FF;
			border-radius: 6px;
			display: flex;
			align-items: center;
			justify-content: center;
			font-size: 16px;
			font-weight: 600;
			color: #FFFFFF;
		}
	}
</style>